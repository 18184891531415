import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-vacancy-alert-dialog',
  templateUrl: './vacancy-alert-dialog.component.html',
  styleUrls: ['./vacancy-alert-dialog.component.scss']
})
export class VacancyAlertDialogComponent implements OnInit {
  public title: string;
  public message: string;
  public reject: boolean;
  public confirm: boolean=true;

  constructor(public dialogRef: MatDialogRef<VacancyAlertDialogComponent>) { }

  ngOnInit() {
  }

}
