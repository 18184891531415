export class Constants {
  public static company = {
    name: "Edoo",
  };

  public static clients = ["Cliente Demà", "Cliente Edoo"];


  public static hosts = {
    test: "https://ww2.recrutador.edoo.com.br/",
    production: "https://recrutador.edoo.com.br/",
    docker: "https://ww3.recrutador.edoo.com.br/",
    localhost: "http://localhost:4200/",
  };
}


export const clientTypes = {
  dema: { id: 7, name: "dema", value: "Cliente Demà" },
  edoo: { id: 1, name: "edoo", value: "Cliente Edoo" },
  todos: { id: null, name: "todos", value: "Todos" },
};

export const requestStatus = {
  loading: "loading",
  ready: "ready",
  initial: "",
};

export const DEFAULTTESTVALUE = {
  id: 7,
  name: "Aceite de candidatura",
  selected: true,
  encryptedYoungsterID: "c2cfb5f25515d321d2bdcd17e478d0f2",
};

export const DEFAULTTESTID = 5;

export const DEMAID = 7;
