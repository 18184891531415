import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class VacancyFormService {

  private stepOneSource: Subject<FormGroup> = new Subject();
  stepOne: Observable<FormGroup> = this.stepOneSource.asObservable();

  private stepTwoSource: Subject<FormGroup> = new Subject();
  stepTwo: Observable<FormGroup> = this.stepTwoSource.asObservable();

  private stepThirdSource: Subject<FormGroup> = new Subject();
  stepThird: Observable<FormGroup> = this.stepThirdSource.asObservable();

  private stepFifthSource: Subject<FormGroup> = new Subject();
  stepFifth: Observable<FormGroup> = this.stepFifthSource.asObservable();


  private stepFourthSource: Subject<FormGroup> = new Subject();
  stepFourth: Observable<FormGroup> = this.stepFourthSource.asObservable();

  public viewVacancy: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  public getVacacyData: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  mainForm = this._formBuilder.group({
    vacancy_for: [0, Validators.required],
    job_title: ['', Validators.required],
    job_description: ['', Validators.required],
    work_start: [''],
    work_end: [''],
    break_time: null,
    do_not_want_time: false,
    scholarship_amount: null,
    transportation: false,
    health: false,
    meal: false,
    dental: false,
    food: false,
    worth: false,
    life: false,
    other_benefits: null,
    benefits: null,
    company_description: null,
    write_to_company_profile: false,
    company_zipcode: ['', Validators.required],
    company_address: [{ value: null, disabled: true }],
    company_number: [{ value: null, disabled: true }, Validators.required],
    company_neighborhood: [{ value: null, disabled: true }, Validators.required],
    company_state: [{ value: null, disabled: true }, Validators.required],
    company_city: [{ value: null, disabled: true }, Validators.required],
    company_complement: [{ value: null, disabled: true }],
    candidate_courses: null,
    candidate_schooling: null,
    schoolGrade: null,
    educationSiriPeriodo: null,
    selectedPsychosocialTags: null,
    isHomeOffice: [false, Validators.required],
    showCompletAddress: [1, Validators.required],
    isConfidential: [false],
    uninformed_work_schedule: [false],
    without_break_time: [false]
  })

  constructor(private _formBuilder: FormBuilder) {
    this.stepOne.subscribe(form =>
      form.valueChanges.subscribe(val => {
        this.mainForm.value.vacancy_for = val.vacancy_for
        let v = this.mainForm.value;
        this.setVacancy(v);

      })
    )

    this.stepTwo.subscribe(
      form => {
        form.valueChanges.subscribe(val => {
          this.mainForm.value.job_title = val.job_title
          this.mainForm.value.job_description = val.job_description;
          let v = this.mainForm.value;

          this.setVacancy(v);
        })
      }
    )

    this.stepThird.subscribe(form => {
      form.valueChanges.subscribe(val => {
        this.mainForm.value.specialNeeds = val.specialNeeds;
        this.mainForm.value.candidate_courses = val.candidate_courses;
        this.mainForm.value.candidate_schooling = val.candidate_schooling;
        this.mainForm.value.educationSiriPeriodo = val.educationSiriPeriodo;
        this.mainForm.value.selectedPsychosocialTags = val.selectedPsychosocialTags;

        let v = this.mainForm.value;

        this.setVacancy(v);
        //this.setVacancy(this.vacancy);
      });
    });


    this.stepFourth.subscribe(form => {
      form.valueChanges.subscribe(val => {
        this.mainForm.value.isHomeOffice = val.isHomeOffice;
        this.mainForm.value.showCompletAddress = val.showCompletAddress;
        this.mainForm.value.isConfidential = val.isConfidential;
        this.mainForm.value.uninformed_work_schedule = val.uninformed_work_schedule;
        this.mainForm.value.without_break_time = val.without_break_time;


        let wStH = '00';
        let wStM = '00';
        let wEtH = '00';
        let wEtM = '00';

        if (val.work_start && val.work_end) {
          wStH = (val.work_start.getHours() < 10 ? '0' : '') + val.work_start.getHours();
          wStM = (val.work_start.getMinutes() < 10 ? '0' : '') + val.work_start.getMinutes();

          wEtH = (val.work_end.getHours() < 10 ? '0' : '') + val.work_end.getHours();
          wEtM = (val.work_end.getMinutes() < 10 ? '0' : '') + val.work_end.getMinutes();
        }


        this.mainForm.value.work_start = wStH + ":" + wStM;
        this.mainForm.value.work_end = wEtH + ":" + wEtM;
        this.mainForm.value.without_break_end = val.without_break_end;

        this.mainForm.value.company_zipcode = val.company_zipcode;
        this.mainForm.value.company_address = val.company_address;
        this.mainForm.value.company_number = val.company_number;
        this.mainForm.value.company_neighborhood = val.company_neighborhood;
        this.mainForm.value.company_state = val.company_state;
        this.mainForm.value.company_city = val.company_city;
        this.mainForm.value.company_complement = val.company_complement;
        this.mainForm.value.company_name = val.company_name;


        let v = this.mainForm.value;
        this.setVacancy(v);
      });
    });


    this.stepFifth.subscribe(form => {
      form.valueChanges.subscribe(val => {
        this.mainForm.value.benefits = val.benefits;
        this.mainForm.value.scholarship_amount = val.scholarship_amount;
        //this.setVacancy(this.vacancy);
        let v = this.mainForm.value;

        this.setVacancy(v);
      });
    });


  }


  stepReady(form: FormGroup, part) {
    switch (part) {
      case 'one': { this.stepOneSource.next(form); break; }
      case 'two': { this.stepTwoSource.next(form); break; }
      case 'three': { this.stepThirdSource.next(form); break; }
      case 'fifth': { this.stepFifthSource.next(form); break; }
      case 'fourth': { this.stepFourthSource.next(form); break; }
    }
  }

  private setVacancy(v: any) {
    this.viewVacancy.next(v);
  }


  public setVacancyData(v: any) {
    this.getVacacyData.next(v);
  }



}

