import {Injectable} from '@angular/core';
import {HttpResponse, HttpClient, HttpParams, HttpErrorResponse} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError, concat} from 'rxjs/operators';

import {HttpErrorHandler, HandleError} from './http-error-handler.service';
import {Vacancy} from '../models/vacancy';
import {environment} from '../../../environments/environment';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/do';


@Injectable()
export class HTTPService {
  baseURL = environment.apiUrl;  // URL to web api
  constructor(
    private http: HttpClient) {
  }

  get getBaseUrl():string {
    return environment.apiUrl;
  }

  get getProUrl():string {
    return environment.professionalAPIURL;
  }

  private buildUrl(path:string):string {
    if(path.includes('http://') || path.includes('https://')) {
      return path;
    } else {
      return environment.apiUrl + path;
    }
  }

  get(path: string, httpOptions?: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    if (localStorage.getItem('token')) {
      headers = headers.append('token', localStorage.getItem('token'));
    }
    const options = {headers, params: (httpOptions && httpOptions.params) ? httpOptions.params : null};
    return this.http.get(this.buildUrl(path), options);
  }

  post(path: string, body: any, httpOptions?: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    if (localStorage.getItem('token')) {
      headers = headers.append('token', localStorage.getItem('token'));
    }
    const options = {headers, params: (httpOptions && httpOptions.params) ? httpOptions.params : null};
    const data = JSON.stringify(body);
    return this.http.post(this.buildUrl(path), data, options);
  }

  put(path: string, body: any, httpOptions?: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    if (localStorage.getItem('token')) {
      headers = headers.append('token', localStorage.getItem('token'));
    }
    const options = {headers, params: (httpOptions && httpOptions.params) ? httpOptions.params : null};
    const data = JSON.stringify(body);
    return this.http.put(this.buildUrl(path), data, options);
  }

  delete(path, body, httpOptions?: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    if (localStorage.getItem('token')) {
      headers = headers.append('token', localStorage.getItem('token'));
    }
    const options = {headers, params: (httpOptions && httpOptions.params) ? httpOptions.params : null};
    const data = JSON.stringify(body);
    return this.http
      .delete(this.buildUrl(path), options);
  }

  httpGet(path, token, params?: HttpParams) {
    let headers = null;
    let param = null;
    if (token !== null) {
      headers = new HttpHeaders().set('token', token);
    }
    if (params) {
      param = params;
    }
    return this.http
      .get(this.buildUrl(path), {headers, params: param})
      .catch(this.handleError);
  }


  httpPost(path, body, token) {
    const data = JSON.stringify(body);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    if (token !== null) {
      headers = headers.append('token', token);
    }
    const options = {headers};

    return this.http
      .post(this.buildUrl(path), data, options)
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  httpPostForm(path, body, token) {
    let headers: HttpHeaders = new HttpHeaders();
    if (token !== null) {
      headers = headers.append('token', token);
    }
    const options = {headers};

    return this.http
      .post(this.buildUrl(path), body, options)
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }


  httpPutForm(path, body, token) {
    let headers: HttpHeaders = new HttpHeaders();
    if (token !== null) {
      headers = headers.append('token', token);
    }
    const options = {headers};

    return this.http
      .put(this.buildUrl(path), body, options)
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  httpPut(path, body, token) {
    const data = JSON.stringify(body);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    if (token !== null) {
      headers = headers.append('token', token);
    }
    const options = {headers};
    return this.http
      .put(this.buildUrl(path), data, options)
      .catch(this.handleError);
  }

  httpPutNoBody(path, token, params?: HttpParams) {
    let headers: HttpHeaders = new HttpHeaders();
    let param = null;
    headers = headers.append('Content-Type', 'application/json');
    if (token !== null) {
      headers = headers.append('token', token);
    }
    if (params) {
      param = params;
    }
    const options = {headers, params: param};
    console.log("show me the thinkgs -> ",this.http
      .put(this.buildUrl(path), options)
      .catch(this.handleError))
    return this.http
      .put(this.buildUrl(path), options)
      .catch(this.handleError);
  }

  httpDelete(path, token, params?: HttpParams) {
    let headers: HttpHeaders = new HttpHeaders();
    let param = null;
    headers = headers.append('Content-Type', 'application/json');
    if (token !== null) {
      headers = headers.append('token', token);
    }
    if (params) {
      param = params;
    }
    const options = {headers, params: param};
    return this.http
      .delete(this.buildUrl(path), options)
      .catch(this.handleError);
  }

  /** GET records from the server */
  getCEPInfo(zipcode: string) {
    return this.http.get(`https://viacep.com.br/ws/${zipcode}/json/`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    });
  }

  private handleError<T>(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

  private getHTTPOptions(httpOptions: any) {
    if (httpOptions) {
      let headers: HttpHeaders;
      if (httpOptions.headers) {
        headers = httpOptions.headers;
      } else {
        headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
      }
      if (localStorage.getItem('token')) {
        headers = headers.append('token', localStorage.getItem('token'));
      }
      httpOptions.headers = headers;
      return httpOptions;
    } else {
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      if (localStorage.getItem('token')) {
        headers = headers.append('token', localStorage.getItem('token'));
      }
      const options = {headers};
      return options;
    }
  }
}

export interface QueryString {
  page?: string;
  limit?: string;
  searchText?: string;
  region?: string;
  fromDate?: string;
  toDate?: string;
  organization?: string;
}

