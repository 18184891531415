import { Injectable } from '@angular/core';
import { HttpHeaders, HttpResponse, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/do';

@Injectable()
export class HttpService {
  constructor(private http: HttpClient) { }
  private handleError(error: any) {
    return Observable.throw(error || '500 internal server error');
  }

  public getBaseUrl() {
    return environment.apiUrl;
  }

  get(path: string, httpOptions?: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    if (localStorage.getItem('token')) {
      headers = headers.append('token', localStorage.getItem('token'));
    }
    const options = { headers: headers, params: (httpOptions && httpOptions.params) ? httpOptions.params : null };
    return this.http.get(environment.apiUrl + path, options);
  }

  httpGet(path, token, params?: HttpParams) {
    let headers = null;
    let param = null;
    if (token !== null) {
      headers = new HttpHeaders().set('token', token);
    }
    if (params) {
      param = params;
    }
    return this.http
      .get(environment.apiUrl + path, { headers: headers, params: param })
      .catch(this.handleError);
  }



  httpPost(path, body, token) {
    const data = JSON.stringify(body);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    if (token !== null) {
      headers = headers.append('token', token);
    }
    const options = { headers: headers };

    return this.http
      .post(environment.apiUrl + path, data, options)
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  httpPut(path, body, token) {
    const data = JSON.stringify(body);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    if (token !== null) {
      headers = headers.append('token', token);
    }
    const options = { headers: headers };
    return this.http
      .put(environment.apiUrl + path, data, options)
      .catch(this.handleError);
  }

  httpDelete(path, token, params?: HttpParams) {
    let headers: HttpHeaders = new HttpHeaders();
    let param = null;
    headers = headers.append('Content-Type', 'application/json');
    if (token !== null) {
      headers = headers.append('token', token);
    }
    if (params) {
      param = params;
    }
    const options = { headers: headers, params: param };
    return this.http
      .delete(environment.apiUrl + path, options)
      .catch(this.handleError);
  }
}
