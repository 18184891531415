import { Injectable } from "@angular/core";
import { HTTPService } from "./http-service";
import "rxjs/Rx";
import { HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";


@Injectable()
export class AuthAdminService {
  constructor(private httpService: HTTPService) { }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      token: localStorage.getItem('token')
    })
  };
  login(admin: any) {
    return this.httpService.post("/admin/login", admin);
  }
  loginPro(admin: any) {
    admin.domainName = 'demajovembyrenapsi'
    return this.httpService.httpPost(this.httpService.getProUrl + "/admin/signin", admin, null);
  }
  checkIfAdminEmailExists(data: any) {
    return this.httpService.post("/admin/check-if-email-exists", data);
  }
  logoutAdmin(data: any) {
    return this.httpService.post('/admin/logout', data);
  }
}
