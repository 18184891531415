import { AuthAdminService } from './shared/services/auth.admin.service ';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTPService } from './shared/services/http-service';
import { HttpErrorHandler } from './shared/services/http-error-handler.service';
import { MessageService } from './shared/services/message.service';
import { AuthOrganizationService } from './shared/services/auth.organization.service';
import { HttpClientModule } from '@angular/common/http';
import { VacancyFormService } from './admin/vacancy/edit/vacancy-form.service';
import { DialogsService } from './shared/services/dialogs.service';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PsychosocialService } from './shared/services/psychosocial.service';
import { HttpService } from './shared/services/http-services';
import { NbLayoutModule, NbThemeModule } from '@nebular/theme';
import { NbEvaIconsModule } from "@nebular/eva-icons";
import { ClientService } from './shared/services/client.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    NbThemeModule.forRoot(),
    NbLayoutModule,
    NbEvaIconsModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    AuthOrganizationService,
    AuthAdminService,
    HTTPService,
    HttpErrorHandler,
    MessageService,
    VacancyFormService,
    DialogsService,
    PsychosocialService,
    HttpService,
    ClientService
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
