import { Component } from '@angular/core';
import {Constants} from './shared/Constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constants = Constants;
  title = this.constants.company + '-admin';
}
