import { Injectable } from "@angular/core";
import { HTTPService } from "./http-service";
import "rxjs/Rx";
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthOrganizationService {
  constructor(private httpService: HTTPService) { }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      token: localStorage.getItem('token')
    })
  };
  login(organization: any) {
    return this.httpService.post("/organization/login", organization);
  }
  register(organization: any) {
    return this.httpService.post("/organization/create", organization);
  }
  checkIfOrganizationEmailExists(data: any) {
    return this.httpService.post("/organization/check-if-email-exists", data);
  }
  checkIfOrganizationCPFExists(data: any) {
    return this.httpService.post("/organization/check-if-cpf-exists", data);
  }
  getOrganizationDetail(id?: any) {
    return this.httpService.get(`/organization/get/${id}`);
  }
  logoutOrganization(data: any) {
    return this.httpService.post('/organization/logout', data);
  }
  saveOrganizationDetails(data: any) {
    return this.httpService.post('/organization/update', data);
  }
  saveLoginDetails(data: any) {
    return this.httpService.post('/organization/updatelogindetails', data);
  }
  uploadProfileImg(data: any) {
    return this.httpService.post('/organization/profileimage', data);
  }
  removeProfileImage(data: any) {
    return this.httpService.post('/organization/removeimage', data);
  }
  recoverPassword(data: any) {
    return this.httpService.post('/organization/recover-password', data);
  }
  setNewPassword(data: any) {
    return this.httpService.post('/organization/set-new-password', data);
  }
  resendEmailVerification(data: any) {
    return this.httpService.post('/organization/resend-email-verification', data);
  }
  validateOrganization() {
    // const organizationHeader = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     token: localStorage.getItem('token')
    //   })
    // };
    return this.httpService.get('/organization/validate');
  }
}
