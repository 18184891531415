import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material';
import { VacancyAlertDialogComponent } from './vacancy-alert-dialog/vacancy-alert-dialog.component';

@Injectable()
export class DialogsService {

    constructor(private dialog: MatDialog) { }

    public confirm(title: string, message: string, reject?: boolean, confirm: boolean = true): Observable<boolean> {

        let dialogRef: MatDialogRef<ConfirmDialogComponent>;
        dialogRef = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: '368px',
            height: '475px',
            width: '90vw',
            panelClass: 'vancancySuccessDialog'
        });
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;
        dialogRef.componentInstance.reject = reject;
        dialogRef.componentInstance.confirm = confirm;
        return dialogRef.afterClosed();
    }
    public vacancyAlertDialog(title: string, message: string, reject?: boolean, confirm: boolean = true): Observable<boolean> {
        let dialogRef: MatDialogRef<VacancyAlertDialogComponent>;
        dialogRef = this.dialog.open(VacancyAlertDialogComponent, {
            maxWidth: '368px',
            minHeight: '376px',
            width: '90vw',
            panelClass: 'vacancyAlertDialog'
        });
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;
        dialogRef.componentInstance.reject = reject;
        dialogRef.componentInstance.confirm = confirm;
        return dialogRef.afterClosed();
    }
}
