import { Injectable } from '@angular/core';
import { HttpService } from './http-services';

@Injectable()
export class PsychosocialService {


    constructor(private httpService: HttpService) { }

    /* to get user personal informations */
    getQuestions() {
        const token = localStorage.getItem('r-token');
        return this.httpService.httpGet('/psychosocial/questions', token);
    }
    /* to upload and save admin Profile image */
    savePsychosocialAnswer(data: any) {
        const token = localStorage.getItem('r-token');
        return this.httpService.httpPost('/psychosocial/save/answer', data, token);
    }
    setAnswersPercentage() {
        const token = localStorage.getItem('r-token');
        return this.httpService.httpGet('/psychosocial/set/percentage', token);
    }
    getPsychosocialPercentage(id: number) {
        const token = localStorage.getItem('r-token');
        return this.httpService.httpGet('/psychosocial/get/percentage/' + id, token);
    }
    getPsychosocialParts() {
      return this.httpService.get('/psychosocial/parts');
    }
}
